import React from "react";
import img1 from "../images/portfolio/image_1.jpg";
import img2 from "../images/portfolio/image_2.jpg";
import img3 from "../images/portfolio/image_3.jpg";
import img4 from "../images/portfolio/image_4.jpg";
import img5 from "../images/portfolio/image_5.jpg";

const Portfolio = () => {
  return (
    <div className="my-4 py-4" id="portfolio">
      <section className="bg-gray-100 py-12" data-aos="zoom-in-down">
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
          Portfolio
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-2xl text-center">
                  Basket 1
                </h4>
                <img src={img1} alt="image1"></img>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-2xl text-center">
                  Basket 2
                </h4>
                <img src={img2} alt="image1"></img>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-2xl text-center">
                  Basket 3
                </h4>
                <img src={img3} alt="image1"></img>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-2xl text-center">
                  Basket 4
                </h4>
                <img src={img4} alt="image1"></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
